a:hover, svg:hover {
  cursor: pointer;
}

.bi {
  &-db {
    &-frame {
      border: none;
      width: 100%;
      height: 100%;
    }
  }
}

.saas {

  &-adv {
    height: 56px;
    line-height: 56px;
    width: 100%;
    position: fixed;
    z-index: 0;
    text-align: center;
    bottom: 0;

    &:hover {
      cursor: pointer;
    }

    &-dom {
      display: inline-flex;
      align-items: center;
    }

    &-txt {
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      margin-right: 15px;
    }

    ul {
      padding-inline-start: 0;
    }

    &:hover &-more {
      visibility: visible;
    }

    &-close {
      position: fixed;
      right: 20px;
      line-height: normal;

      &-ico {
        width: 16px;
        height: 16px;
        fill: #ffffff;
        opacity: 0.7;
        margin-top: 20px;
        margin-bottom: 20px;

        &:hover {
          opacity: 1;
        }
      }
    }

    &-btn {
      background-color: rgba(0, 0, 0, 0);
      width: 80px;
      height: 30px;
      border: 1px solid #ffffff;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #19af5d;
        border: none;
      }
    }

    &-more {
      visibility: hidden;
      background-color: white;
      position: absolute;
      top: -50px;
      right: 100px;

      li {
        list-style: none;
        float: left;
      }

      &-qrcode {
        background-color: #19af5d;

        &:hover .saas-adv-more-qrcode-img {
          visibility: visible;
        }

        &-img {
          visibility: hidden;
          position: absolute;
          top: -160px;
          left: 10px;
          line-height: 0;
          padding: 25px 25px 0 25px;
          background-color: #829cf4;
          border-radius: 3px;

          &::before {
            content: "";
            display: block;
            position: absolute;
            margin-left: -6px;
            left: 50%;
            top: 100%;
            border-left: 6px solid #ffffff00;
            border-right: 6px solid #ffffff00;
            border-top: 6px solid #829cf4;
          }
        }
      }
    }
  }
}

.adv-share {
  position: fixed;
  right: 16px;
  bottom: 72px;
  background-color: #FFFFFF;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  height: 44px;
  margin: 0 auto;
  text-align: center;
  user-select: none;

  &-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover &-action {
    width: 108px;
    transition: all .5s;
  }

  &:hover .adv-share-link {
    right: 100px;
    transition: all .5s;
  }

  &:hover &-dom {
    right: 60px;
    transition: all .5s;
  }

  &-action {
    height: 30px;
    display: inline-block;
    vertical-align: super;
    width: 0;
    transition: all .5s;
  }

  &-action:hover .adv-share-dom {
    z-index: 0;
  }

  &-action:hover .adv-share-link {
    z-index: 0;
  }

  span {
    font-size: 14px;
    color: #333333;
    margin-right: 10px;
  }

  ul {
    padding-inline-start: 0;

    li {
      display: inline-block;
    }
  }

  &-ico {
    width: 30px;
    height: 30px;
  }

  &-link {
    position: absolute;
    right: 3px;
    z-index: -1;
    transition: all .5s;
    display: flex;
    justify-content: center;

    &:hover::before {
      content: var(--tips);
      position: absolute;
      width: max-content;
      height: 26px;
      line-height: 26px;
      background-color: #ffffff;
      border-radius: 2px;
      padding: 0 2px 0 2px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
      color: #333333;
      font-size: 12px;
      top: -100%;
    }
  }

  &-dom {
    position: absolute;
    right: 3px;
    z-index: -1;
    transition: all .5s;

    &:hover {
      .adv-share-qrcode-img {
        visibility: visible;
      }
    }
  }

  &-svg {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    display: inline-block;
  }

  &-qrcode {

    &-img {
      visibility: hidden;
      background-color: #ffffff;
      border-radius: 2px;
      position: absolute;
      bottom: 46px;
      left: -39px;
      padding: 10px 10px 6px 10px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

      p {
        color: #333333;
        font-size: 12px;
        margin: 0;
      }
    }
  }

}

@media screen and (max-device-width: 1000px) {
  .saas {
    &-adv {
      height: 60px;
      text-align: left;
      line-height: 16px;

      &-dom {
        height: 60px;
      }

      &-txt {
        margin-left: 15px;
        font-size: 12px;
        margin-right: 8px;
      }

      &-btn {
        width: max-content;
        height: 25px;
        border: 1px solid #ffffff;
        border-radius: 2px;
        font-size: 12px;
        color: #ffffff;
      }

      &-close {
        right: 15px;

        &-ico {
          width: 12px;
          height: 12px;
          margin-top: 24px;
          margin-bottom: 24px;
        }
      }
    }
  }
}