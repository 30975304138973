.modal_mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.modal_content {
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
}

.modal_head {
  background-color: #F8F9FA;
  line-height: 40px;
  border-radius: 6px 6px 0 0;
  height: 48px;
  //cursor: move;
  user-select: none;
}

.modal_head h3 {
  margin: 0;
  padding: 5px 0 5px 20px;
  font-size: 16px;
  color: #333333;
  width: 90%;
  float: left;
}

.share_close_button {
  width: 16px;
  height: 16px;
  float: right;
  display: inline-block;
  margin-right: 20px;
  margin-top: 8px;

  path {
    fill: #c2c2c2 !important;
    stroke: #c2c2c2 !important;
  }

  &:hover {
    path {
      fill: #666666 !important;
      stroke: #666666 !important;
    }
  }
}

#modal_body {

}

.modal_footer {
  position: absolute;
  bottom: -56px;
  width: 100%;
  background-color: #FFFFFF;
  padding-top: 6px;
  padding-bottom: 20px;
  border-radius: 0 0 6px 6px;
}

.btn {
  float: right;
  padding-right: 38px;

  button {
    min-width: 86px;
    height: 32px;
    border: none;
    border-radius: 2px;
    margin-left: 20px !important;
    margin-right: 0 !important;

    &:hover {
      cursor: pointer;
    }
  }

  #ok {
    background-color: #19af5d;
    color: #FFFFFF;
  }

  #ok:hover {
    background-color: rgba(25, 175, 93, 0.8);
  }

  #cancel {
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    color: #333333;
  }

  #cancel:hover {
    color: #19af5d;
    border-color: #19af5d;
  }
}

.modal-mobile {
  &-content {
    background-color: #ffffff;
    height: 100%;

    &-hr {
      margin: 0 !important;
      border: unset;
      border-top: 1px solid #EFEFEF;
    }
    &-body{
      height: 100vh;
      overflow-y: auto;
    }
  }

  &-head {
    height: 50px;
    line-height: 50px;
    margin: 0 15px 0 15px;
    font-size: 18px;
    text-align: center;

    &-back {
      float: left;
      height: 50px;
      width: 18px;

      &-icon {
        width: 18px;
        height: 18px;
        fill: #333333;
      }
    }

    &-ok {
      float: right;
      height: 50px;
      line-height: 50px;

      a {
        font-size: 15px !important;
        color: #19af5d;
      }
    }
  }
}