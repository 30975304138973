html {
  width: 100%;
  height: 100%;
  box-sizing: initial !important;
}

body {
  margin: 0 auto;
}

.Tem_Container {
  margin: 0 auto;
  width: calc(100% - 64px);
}

img {
  border: none;
}

ol,
ul,
li,
dl,
dd,
dt {
  list-style: none;
}

a {
  text-decoration: none;
}

a:link {
  color: #242424;
}

a:visited {
  color: #242424;
}

.head {
  width: 100%;
  height: 64px;
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
}

.head .hd_content {
  z-index: 999;
  height: 64px;
  margin: 0 auto;
}

.fm_logo {
  float: left;
  width: 144px;
  height: 60px;
  position: relative;
  background-color: #fff;
  background-repeat: round !important;
}

.fm_nav {
  float: left;
  height: 64px;
  font-size: 16px;
}

.fm_nav ul {
  float: left;
  padding-inline-start: 0;
}

.fm_nav a.current {
  border-bottom: 3px solid #3cbc66;
  height: 61px;
  margin-bottom: -1px;
}

.fm_nav a.current {
  color: #3cbc66;
  fill: #3cbc66;
}

.fm_nav a.current .icon_style {
  color: #3cbc66;
  fill: #3cbc66;
}

.fm_nav a:hover {
  color: #3cbc66;
  fill: #3cbc66;
}

.fm_nav a:hover .icon_style {
  color: #3cbc66;
  fill: #3cbc66;
}

.fm_nav a {
  float: left;
  padding: 0 3px;
  margin: 0 40px 0 0;
  font-weight: bold;
  line-height: 69px;
  color: #666666;
  cursor: pointer !important;
}

.fm_userinfo {
  min-width: 130px;
  margin-top: 18px;
  position: absolute;
  right: 32px;
  border: 1px #9d9d9d solid;
  border-radius: 6px;
  //color: #666666;
  display: flex;
  align-items: center;
}

.fm_userinfo a {
  color: #666666;
}

.fm_userinfo a:hover {
  color: #3cbc66;
}

.icon_downArrow {
  margin-left: 5px;
  width: 12px;
  height: 12px;
  vertical-align: top;
  position: relative;
  top: 8px;
  fill: #666666;
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  display: inline-block;

  animation: icon_downArrow_action 3s;
}

.user-name:hover .icon_downArrow {
  fill: #3cbc66;
  top: 12px;
  transform: rotateX(180deg);
}

.user_name{
  display: inline-block;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.user-name:hover {
  color: #29b758;
  cursor: pointer;

  .user_operate {
    display: block;
  }
}

.activities-rolling{
  width: 180px;
  height: 20px;
  overflow: hidden;
  color: #FA3A3A;
  //background-color: #ffff00;
  border-radius: 10px;
  margin-right: 16px;

  div {
    text-align: right;
  }
}

.user_operate {
  display: none;
  background-color: #ffffff;
  position: absolute;
  right: 0;
  width: 150px;
  color: #333333;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  padding: 5px 0 5px 0;

  a {
    height: 32px;
    border: none;
    background: #fff;
    line-height: 32px;

    .a_dom{
      padding-left: 16px;
      padding-right: 12px;
      overflow: hidden;

      .lang_icon{
        width: 12px;
        height: 12px;
        margin: 0 0 0 20px;
      }
    }

    :hover{
      background-color: #f1f2f3;
    }
  }
}

#a_lang:hover .lang-options {
  display: block;
}
.lang-split{
  width: 5px;
  height: 100%;
  top: 0;
  right: 100%;
  position: absolute;
}
.lang-options{
  display: none;
  position: absolute;
  right: calc(100% + 5px);
  bottom: 0;
  width: 130px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  text-align: left;
  background-color: #ffffff;
  padding: 5px 0 5px 0;

  &:hover{
    background-color: #ffffff !important;
  }
    ol {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;

      li{
         > div{
          padding: 0 16px 0 16px;
           :hover {
             background-color: #f1f2f3;
           }
        }
        color: #333333;
      }

      &:hover{
        background-color: #ffffff;
      }
    }

    .lang_checked{
      margin-top: 0;
      margin-right: 0;
      float: right;
      color: #333333;
    }
}

.svg_css {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 2px;
}

.svg_css_text {
  vertical-align: top;
  color: #333333;
}

.user_icon {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}

.vip-icon{
  width: 25px;
  height: 25px;
  margin-right: 5px;
  margin-top: 5px;
}
.vip-info{
  text-align: center;
  border-bottom: 1px solid #efefef;
  .info-open-vip{
    height: 34px;
    margin: 10px;
    background: white;
    border: 1px solid;
    color: #3cbc66;

    &:hover {
      cursor: pointer;
      background: #29b758;
      color: #fff;
    }
  }
}
.open-vip-tip{
  margin-right: 32px;
}

.open-vip-tip-btn{
  background: linear-gradient(to bottom, #FFDCA2, #FF8F6B);
  color: #593E06;
  border-radius: 16px;
  width: 86px;
  height: 31px;
  cursor: pointer;

  span{
    position: absolute;
    padding: 2px 5px 2px 5px;
    margin-left: -20px;
    margin-top: -20px;
    color: #ffffff;
    font-size: 12px;
    border-radius: 92px 92px 92px 0;
    background: #FA3A3A;
  }
}

.open-vip-tip-btn:hover{
  background: linear-gradient(to bottom, rgba(255,220,162,0.7), rgba(255,143,107,0.7));
}
.open-vip-message{
  display: none;
  width: auto;
  padding: 5px 0 5px 0;
  position: absolute;
  padding: 0 5px 0 5px;
  color: #ffffff;
  font-size: 12px;
  margin-left: 30px;
  margin-top: -30px;
  border: 1px solid;
  border-radius: 10px 92px 92px 0;
  background: #FA3A3A;
}

#home:hover, #club:hover, #logout:hover {
  color: #333333 !important;
}

.content {
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-device-width: 1000px) {
  .head {
    display: none;
  }
}

.icon_style {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  position: relative;
  top: 2px;
  fill: #666666;
}

//#username {
//  overflow: hidden;
//  text-overflow: ellipsis;
//  white-space: nowrap;
//  height: 46px;
//  min-width: 130px;
//  text-align: right;
//}

#span_name {
  vertical-align: top;
  position: relative;
  line-height: 32px;
}