.content {
  margin: 64px 0 0 0;
  width: 100%;
  background-color: #f8f9fa;
  min-width: 900px;
  // min-height: 1500px;
  //padding-bottom: 20px;
}

.data_Container {
  padding: 20px 32px 0 32px;
  //max-width: 1366px !important;
  //min-width: 900px !important;
  //width: calc(100% - 64px);
}

.btn-default{
  color: #333333;
  background: #FFFFFF;
  font-size: 14px;
  width: 86px;
  border: 1px solid #d9d9d9;
  height: 32px;
  border-radius:2px;
  cursor: pointer;
}

.btn-default:hover {
  color: #19af5d;
}

.btn-active{
  background-color: rgba(25,175,93,0.1);
  font-size: 14px;
  border: 1px solid #19af5d;
  width: 86px;
  height: 32px;
  border-radius:2px;
  color: #19af5d;
  cursor: pointer;
}

.btn-lang{
  width: 100px!important;
}

.totalSize{
  float: right;
  font-size: 14px;
  color: #333333;
}
.btn-submit{
  background-color: #19af5d;
  color: #ffffff;
  border: none;
  width: 86px;
  height: 32px;
  border-radius:2px;
  cursor: pointer;
  margin-top: -10px;

  &:disabled {
    background-color: #f5f5f5;
    color: #B8B8B8;
    border: 1px solid #d9d9d9;
  }
}

.Tem_Item {
  width: calc(100% - 8px);
  margin: 0 auto;
  padding: 12px 8px 0 0;
}
.data_empty {
  background-image: url("../../assets/images/saas/data-empty.svg");
  width: 170px;
  height: 170px;
  margin: 0 auto 5px auto;
}