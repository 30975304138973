.fail-content{
  display: inline-block;
  text-align: center;
  height: 800px;
  width: 100%;

  .img-1 {
    margin-top: 200px;
    img{
      width: 170px;
      height: 170px;
    }
  }
  .msg{
    text-align: center;
    font-size: 14px;
    color: #666666;
  }

  .action{
    margin-top: 20px;
    text-align: center;
  }

  .btn{
    width: 100px;
    height: 36px;
    float: unset;
    color: white;
    background: #3cbc66;
    padding:0;
    cursor: pointer;
  }
}