body {
   font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Tahoma, Arial, PingFang SC, Hiragino Sans GB,
   Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;
}

@media screen and (max-width: 820px) {
   .LoginBG {
      background-image: none !important;
   }
   .bodyDiv {
      height: 100vh !important;
      width: 100vw !important;
      .LoginBox {
         position: static;
         height: 100%;
         width: 100%;
         border-radius: 0;
         .LoginDiv {
            padding: 30px;
            height: calc(100% - 60px);
            width: calc(100% - 60px);
            &-title {
               color: #333333;
               font-size: 22px;
               font-weight: bolder;
            }
            &-tab {
               margin-top: 30px;
               .selecteTab {
                  color: #19af5d;
                  border-bottom: 2px solid #19af5d !important;
               }
            }
            &-tabItem {
               font-size: 16px;
               margin: 0;
               color: #333333;
               &:nth-child(1) {
                  margin-right: 50px;
               }
               &:hover {
                  border: none;
               }
            }
            &-tipBox {
               margin-top: 20px;
               height: 13px;
               line-height: 13px;
            }
            &-tip {
               font-size: 13px;
               color: #666666;
            }
            form {
               position: relative;
               .seccodeBox {
                  width: 100%;
                  margin-bottom: 50px;
                  //border-bottom: 1px solid #efefef;
                  .select {
                     width: max-content;
                     &-text {
                        font-size: 15px;
                        width: max-content;
                        color: #333333;
                     }
                     &-icon {
                        margin-left: 2px;
                        font-size: 10px;
                        right: 10px;
                        width: 10px;
                        height: 10px;
                        path {
                           fill: #333333;
                        }
                     }
                  }
                  input {
                     font-size: 15px;
                     color: #333333;
                  }
               }
               .seccodeUnSend,
               .seccodeSended {
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  border: none;
                  width: inherit;
                  //color: #db9849;
               }
               .seccodeSended {
                  color: #d7d7d7;
               }
               .seccodeUnSend {
                  color: #333333;
               }
               .seccodeDom {
                  justify-content: space-between;
               }
               .labelBox label {
                  font-size: 13px;
                  color: #666666;
               }
               .submitGreenBox,
               .submitGrayBox {
                  padding-top: 20px;
                  height: 40px;
                  .commitBtn {
                     width: 100%;
                     height: 100%;
                     border-radius: 5px;
                     &-label {
                        font-size: 15px;
                     }
                  }
               }
               .serviceTerms {
                  padding-top: 15px;
                  & > div > span {
                     font-size: 13px;
                     color: #666666;
                  }
                  & > div > a {
                     font-size: 13px;
                  }
               }
               .forgetPasswordBox {
                  margin-top: 15px;
                  margin-bottom: 0;
                  .forgetPassword {
                     font-size: 15px;
                     color: #19af5d;
                  }
               }
               .skipBox {
                  display: flex;
                  justify-content: center;
                  .errInfo {
                     position: absolute;
                     opacity: 0;
                     bottom: 158px;
                     min-height: 40px;
                     width: max-content;
                     line-height: 40px;
                     background-color: rgba(0, 0, 0, 0.8);
                     color: #fff;
                     font-size: 15px;
                     padding-left: 10px;
                     padding-right: 10px;
                     border-radius: 5px;
                     text-align: center;
                     text-overflow: initial;
                     white-space: normal;
                     word-break: break-all;
                     animation: fadeBox 3s;
                  }
                  .skip {
                     display: none;
                  }
               }
            }
         }
      }
   }
   @keyframes fadeBox {
      0% {
         opacity: 1;
      }
      99% {
         opacity: 1;
      }
      100% {
         opacity: 0;
      }
   }
   @-webkit-keyframes fadeBox {
      0% {
         opacity: 1;
      }
      99% {
         opacity: 1;
      }
      100% {
         opacity: 0;
      }
   }
}
.signContent {
   background-color: white;
}

//.ant-picker {
//   width: 300px;
//   height: 32px;
//   font-size: 14px;
//   color: #333333;
//   outline: none;
//   border: 1px solid #D9D9D9;
//   //margin-left: 10px;
//   //margin-bottom: 50px;
//   //padding-left: 8px;
//}

//.ant-picker-input{
//   width: 300px;
//   height: 32px;
//   font-size: 14px;
//   color: #333333;
//   outline: none;
//   border: 1px solid #D9D9D9;
//   margin-left: 10px;
//   margin-bottom: 50px;
//   padding-left: 8px;
//}

.bodyDiv {
   height: 100vh;
   width: 100vw;
   background-color: rgb(67, 159, 241);
   position: relative;
   display: flex;
   justify-content: right;
   align-items: center;
   z-index: 999;
}

.LoginBox {
   min-height: 470px;
   width: 426px;
   position: absolute;
   right: 130px;
   top: calc(50% - 295px);
   background-color: #fff;
   border-radius: 8px;
}

.LoginBG {
   background-image: url(./../../assets/images/LoginBG.png);
   background-repeat: no-repeat;
   background-position: 50%;
   background-size: cover;
}

.LoginDiv {
   height: calc(100% - 80px);
   width: calc(100% - 80px);
   border-radius: 6px;
   padding: 40px;

   &-title {
      font-size: 22px;
      line-height: 22px;
      color: #515151;
      padding: 0;
      margin: 0;
   }

   &-tab {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: left;
      margin-top: 40px;
   }

   &-tabItem {
      margin-right: 60px;
      cursor: pointer;
      height: 22px;
      line-height: 22px;
      font-size: 16px;
      color: #515151;
      &:hover {
         color: #0dbc6f;
         opacity: 1;
         border-bottom: 3px solid #0dbc6f;
         padding-bottom: 6px;
      }
   }
   &-tipBox {
      margin-top: 20px;
      height: 16px;
      line-height: 16px;
   }
   &-tip {
      padding: 0;
      margin: 0;
      font-size: 12px;
      line-height: 12px;
      color: #515151;
   }
}

.selecteTab {
   color: #0dbc6f;
   opacity: 1;
   border-bottom: 3px solid #0dbc6f;
   padding-bottom: 6px;
}

.labelBox {
   margin-top: 20px;
   height: 16px;
   line-height: 16px;
   label {
      padding: 0;
      margin: 0;
      font-size: 12px;
      line-height: 12px;
      color: #515151;
   }
   .tip {
      opacity: 0.8;
   }
}

.mark-font {
   display: inline-flex;
   align-items: center;
   margin: 0 4px;
   color: red;
   font-size: 12px;
}

.titleBox {
   position: relative;
   width: 50%;
   padding-left: 48px;
   padding-right: 48px;
   //display: flex;
   align-items: center;
   margin: 0 auto;
   position: absolute;
   top: 18%;
   top: 18%;
   left: 23%;
   margin-bottom: 7%;
   display: block;

   .title1 {
      display: block;
      font-size: 38px;
      color: #333333;
      font-weight: bolder;
   }
   .title2 {
      font-size: 20px;
      color: #3f3f3f;
   }
}

.headerBox {
   .guideBox {
      position: relative;
      width: 50%;
      height: 160px;
      z-index: 2;
      padding-left: 48px;
      padding-right: 48px;
      padding-top: 38px;
      //display: flex;
      align-items: center;
      margin: 0 auto;
      margin-top: -3%;
      background-image: url("../../assets/images/saichengbeijing.png");
      background-size: 100% 100%;

      .introduce {
         font-size: 28px;
         font-family: SourceHanSansCN-Medium, SourceHanSansCN;
         font-weight: 500;
         color: #0b9749;
         line-height: 20px;
         margin-bottom: 20px;
      }

      .description {
         font-size: 18px;
         font-weight: 500;
         color: #373737;
         line-height: 20px;
         margin-bottom: 20px;
      }

      .activity {
         font-size: 18px;
         color: #F99E10;
         line-height: 20px;
         font-weight: lighter;
      }
   }
}
.imgBox {
   position: relative;
   width: 100%;
   height: 450px;
   background-image: url("../../assets/images/banner1.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
}
.authBox {
   .authInfo {
      .title {
         margin-top: 50px;
         display: block;
         font-size: 16px;
         color: #333333;
      }
      .guide {
         font-size: 14px;
         color: #F99E10;
      }
   }
}

.seccodeBox {
   height: 40px;
   font-size: 14px;
   color: #333333;
   position: relative;
   margin-bottom: 15px;
   //width: 100%;
   //border-bottom: 1px solid #e5e5e5;
   .seccodeDom {
      //display: flex;
      input {
         width: 300px;
         height: 32px;
         font-size: 14px;
         color: #333333;
         outline: none;
         border: 1px solid #D9D9D9;
         margin-left: 10px;
         margin-bottom: 50px;
         padding-left: 8px;
      }
      .seccodeUnSend {
         width: 70px;
         white-space: nowrap;
         height: 14px;
         line-height: 14px;
         font-size: 14px;
         color: #515151;
         cursor: pointer;
         padding: 0 0 0 6px;
         margin: 16px 0 0;
         border-left: 1px solid #e5e5e5;
         &:hover {
            white-space: nowrap;
            font-size: 14px;
            color: #29b758;
         }
      }
      .seccodeSended {
         white-space: nowrap;
         height: 14px;
         line-height: 14px;
         font-size: 14px;
         color: #d8d8d8;
         padding: 0 0 0 6px;
         margin: 16px 0 0;
         border-left: 1px solid #e5e5e5;
      }

      .sendLabel {
         position: absolute;
         padding-top: 5px;
         //margin-top: 4px;
         //margin-left: 10px;
         display: inline;
      }
   }
}
.formBox {
   position: relative;
   width: 600px;
   height: auto;
   text-align: center;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   margin: auto;
   margin-top: 54px;
   //margin-left: ;
}

.serviceBox {
   padding-top: 20px;
   display: flex;
   align-items: center;
   span {
      color: #515151;
      font-size: 14px;
      height: 26px;
      line-height: 26px;
   }
   .checkbox {
      display: inline-block;
      white-space: nowrap;
      line-height: 26px;
      cursor: pointer;
      input {
         cursor: pointer;
         vertical-align: middle;
      }
      .checkboxLabel {
         margin: 0 0.4375rem;
         padding: 0 0.1875rem;
         display: inline-block;
         word-break: break-all;
      }
   }
   .terms {
      font-size: 14px;
      cursor: pointer;
      color: #0dbc6f;
      text-decoration: none;
      &:hover {
         &:hover {
            text-decoration: underline;
         }
      }
   }
}

.forgetPasswordBox {
   margin-top: 10px;
   margin-bottom: -15px;
   height: 20px;
   line-height: 20px;
   text-align: right;
   .forgetPassword {
      font-size: 14px;
      color: #0dbc6f;
   }
}

.submitGrayBox,
.submitGreenBox {
   height: 36px;
   padding-top: 30px;
}

.commitBtn {
   border: 0;
   cursor: pointer;
   font-size: 14px;
   width: 100px;
   text-align: center;
   padding: 0;
   height: 32px;
   border-radius: 2px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   box-sizing: border-box;
   .submitGrayBox & {
      color: #d8d8d8;
      border-color: #d8d8d8;
      background: #d8d8d8;
   }
   .submitGreenBox & {
      color: #29b758;
      background: #29b758;
   }
   &-label {
      color: #fff !important;
      font-size: 16px;
      margin: 0;
      padding: 0;
      line-height: 1.625rem;
      display: inline-block;
   }
}

.serviceTerms {
   padding-top: 20px;
   display: flex;
   text-align: center;
   justify-content: center;
   span {
      color: #515151;
      font-size: 14px;
      height: 26px;
      line-height: 26px;
   }
   .terms {
      font-size: 14px;
      cursor: pointer;
      color: #0dbc6f;
      text-decoration: none;
      &:hover {
         text-decoration: underline;
      }
   }
}

.skipBox {
   position: relative;
   .errInfo {
      text-align: left;
      margin-left: 185px;
      line-height: 30px;
      cursor: pointer;
      max-width: 270px;
      font-size: 14px;
      color: #f75252;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
   }
   .skip {
      float: right;
      font-size: 12px;
      color: #515151;
      opacity: 0.6;
      line-height: 30px;
      height: 30px;
      cursor: pointer;
   }
}
.signTitle {
   margin-bottom: 24px;
   font-size: 28px;
   font-family: SourceHanSansCN-Medium, SourceHanSansCN;
   font-weight: 500;
   width: 100%;
   color: #3e3d3d;
   display: flex;
   /* align-items: center; */
   justify-content: center;

   background-size: 100% 100%;
   line-height: 20px;
   .icon {
      background-image: url("../../assets/images/saas/title-bg.png");
      width: 217px;
      height: 44px;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
   }
}
.graTime {
   font-size: 14px;
   color: #333333;
}

.loginInputBox {
   height: 40px;
   font-size: 14px;
   color: #333333;
   margin-bottom: 15px;
   //border-bottom: 1px solid #e5e5e5;
   //display: flex;
   position: relative;
   input {
      width: 300px;
      height: 32px;
      max-width: 346px;
      font-size: 14px;
      color: #333333;
      outline: none;
      border: 1px solid #D9D9D9;;
      margin-left: 10px;
      margin-bottom: 15px;
      padding-left: 8px;
   }
}
.codeBox {
   height: 40px;
   font-size: 14px;
   color: #333333;
   //border-bottom: 1px solid #e5e5e5;
   //display: flex;
   position: relative;
   input {
      width: 300px !important;
      height: 32px;
      //max-width: 346px;
      font-size: 14px;
      color: #333333;
      outline: none;
      border: 1px solid #D9D9D9;
      margin-left: 10px;
      margin-bottom: 15px;
      padding-left: 8px;
   }
}

.autoLoginBox {
   padding-top: 20px;
   display: block;
   .checkbox {
      display: inline-block;
      white-space: nowrap;
      line-height: 1.625rem;
      cursor: pointer;
      input {
         vertical-align: middle;
      }
   }
   .checkboxLabel {
      font-size: 14px;
      color: #515151;
      margin: 0 0.4375rem;
      padding: 0 0.1875rem;
      line-height: 26px;
      height: 26px;
      display: inline-block;
      word-break: break-all;
   }
}

.mixBox {
   display: flex;
   &-left {
      margin: 0;
      width: 160px;
   }
   &-right {
      margin: 0 0 0 20px;
      width: 160px;
   }
}

.mixInputBox {
   height: 40px;
   display: flex;
   .mixBox &-left {
      display: flex;
      margin: 0;
      width: 160px;
      border-bottom: 1px solid #e5e5e5;
   }
   .mixBox &-right {
      display: flex;
      margin: 0 0 0 20px;
      width: 166px;
      border-bottom: 1px solid #e5e5e5;
   }

   input {
      width: 100%;
      max-width: 346px;
      font-size: 14px;
      color: #333333;
      border: 0;
      outline: none;
   }

}

.ant-picker:hover,
.ant-picker-focused {
   border-color: #19af5d;
}

.ant-picker-focused {
   box-shadow: 0 0 0 0px rgba(0,0,0,0);
}

.dateExclude {
   input:enabled:not([readonly]):hover,
   input:enabled:not([readonly]):not([type=checkbox]):focus {
      border: #FFFFFF;

   }
}

input:-ms-input-placeholder {
   color: rgb(185, 185, 185);
} /* Internet Explorer 10+ */
input::-webkit-input-placeholder {
   color: rgb(185, 185, 185);
} /* WebKit browsers */
input::-moz-placeholder {
   color: rgb(185, 185, 185);
} /* Mozilla Firefox 4 to 18 */
input:-moz-placeholder {
   color: rgb(185, 185, 185);
} /* Mozilla Firefox 19+ */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none !important;
}
input[type='number'] {
   -moz-appearance: textfield;
}
