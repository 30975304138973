.help_body{
  background-color: #f8f9fa;
  margin-top: 96px;
  margin-bottom: 100px;
  padding: 0 10px 0 10px;

  .help_body_tips{

    span{
      font-size: 18px;
      font-weight: bold;
    }

    a{
      float: right;
      margin-right: 32px;
    }
  }
}
.noTips {
  height: 700px;
  overflow-y: auto;
}
.help_process{
  margin-left: 32px;
  background-image: url(./../../assets/images/saas/process.svg);
  display: inline-block;

  span{
    font-size: 18px;
    font-weight: bold;
  }
}
.help_body_recommend{
  margin-top: 32px;
}
.recommend-content{
  width: 52%;
  text-align: center;
  margin-top: 10px;

  .recommend-item{
    width: 165px;
    height: 100px;
    display: inline-block;
  }

  .item_break {
    height: 6px;
  }

  .item_name{
    font-size: 16px;
    color: #333333;
  }

  .item_des{
    font-size: 14px;
    color: #666666;
  }

  .recommend_icon_style {
    width: 72px;
    height: 72px;
  }

  .recommend_icon_style:hover {
    transform: translate(0, -7.2px) scale(1.2);
  }
}
.help_content {
  width: 85%;
  margin: 12px auto;
  text-align: center;
}

.help_step {
  display: inline-block;
  text-align: center;
  width: calc(calc(100% - 200px) / 3);

  a{
    color: #19af5d;
  }

  a:hover {
    text-decoration: underline;
  }
}

.help_title {
  margin-bottom: 60px;
}

.step_num {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.step_break {
  height: 20px;
}

.step_info {
  font-size: 16px;
  color: #666666;
}

.help_icon_style {
  width: 100%;
  height: 100%;
}

.help_step_split {
  width: 100px;
  height: 100px;
  display: inline-block;
}
.recommend_step_split {
  width: 80px;
  height: 100px;
  display: inline-block;
}
.help_more{
  float: right;
  overflow: hidden;

  .svg_css{
    width: 12px;
    height: 12px;
    margin: 0 0 0 2px;
  }
}

.help_more:hover {
  a{
    color: #19af5d;
  }
  .svg_css{
    fill: #3cbc66;
  }
}

//.help_more{
//  margin-top: 180px;
//  .help_more_auto{
//    text-align: center;
//  }
//}
.help_more_info{
  font-size: 14px;
  color: #666666 !important;
  margin: auto;

  &:hover{
    color: #19af5d !important;
    cursor: pointer;
  }
}