.tips {
  position: fixed;
  top: 10px;
  right: 0;
  width: 300px;
  z-index: 999999;
  display: block;
  margin-right: 30px;
}

.tip {
  text-align: left;
  margin-top: 10px;
  padding: 16px 20px 20px 20px;
  border-radius: 6px;
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
}

.icon_head {

}

.tips_type {
  font-size: 16px;
  color: #333333;
  margin-left: 12px;
  line-height: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
}

.icon_success {
  background-image: url("../../assets/images/saas/icon-success.svg");
}

.icon_warning {
  background-image: url("../../assets/images/saas/icon-warning.svg");
}

.title_left {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.tips_title {
  display: inline-block;
  font-size: 14px;
  color: #666666;
  margin-left: 12px;
  margin-top: 3px;
  width: calc(100% - 36px);
  word-break: break-all;
}

.tips_close_button {
  width: 16px;
  height: 16px;
  float: right;
  display: inline-block;

  path {
    fill: #c2c2c2 !important;
    stroke: #c2c2c2 !important;
  }

  &:hover {
    path {
      fill: #666666 !important;
      stroke: #666666 !important;
    }
  }
}

.tips_icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: 24px;
  background-repeat: no-repeat;
}

@keyframes action {
  to {
    filter: opacity(0);
    transform: translateX(150px);
    background-color: white;
  }
}