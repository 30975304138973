.paging-table{
  width: 100%;
  text-align: left;
  background-color: #ffffff;
  border-collapse: collapse;
  border-spacing: 2px;

  tr th,tr td{
    padding: 0 10px 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  thead{
    background-color: #eff0f1;
    height: 40px;
    font-size: 14px;
    color: #333333;
    font-weight: bold;
    table-layout: fixed;
    width: 100%;
    display: table;

    tr th{
      border-left: 2px solid #ffffff;

      input[type=checkbox]{
        margin-left: 4px;
      }
    }
  }
  tbody{
    overflow-y:auto;
    display: block;
    height: calc(100vh - 340px);
  }

  tbody tr{
    height: 40px;
    font-size: 14px;
    color: #333333;
    border-bottom: 1px solid #EFEFEF;
    background-color: #ffffff;
    display: table;
    width: 100%;
    table-layout: fixed;

    &:hover:not(.row-checked) {
      background-color: #fafafa;
    }

    td{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .click-action{
      color: #333333;
      margin-right: 5px;

      &:hover{
        color: #3cbc66;
        fill: #3cbc66;
      }
    }
    .action-icon {
      display: inline-block;
      margin-right: 2px;

      svg {
        vertical-align: sub;
      }
    }
  }

  .row-checked{
    background-color: #E9F9F0;
  }

  input[type="checkbox"]{
  }

  .head-checkbox,.row-checkbox{
    width: 56px;
    height: 40px;
    text-align: center;
    padding: 0;
    border: none;
  }

  .select-part {
    appearance: none;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    position: relative;
    border: 1px solid #D9D9D9;
    background-color: #FFFFFF;
    margin: 3px;
    transition: .2s;
  }

  .select-part:checked:after {
    content: "";
    background-color: #19af5d;
    position: absolute;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    border-radius: 0;
    font-weight: unset;
    line-height: unset;
    border: unset;
    transform: translate(-50%, -50%);
  }

  .table-header{

  }

  .table-header-action{
    width: 100px;
    min-width: 100px;
  }
}