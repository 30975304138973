.loader {
   padding: 20px 0 20px 0;
   font-size: 14px;
   color: #929292;
   margin: 0 auto;
   display: flex;
   justify-content: center;
   align-items: center;
   &-loadingGif {
      width: 16px;
      height: 16px;
      background-image: url(./../../assets/images/saas/loading.svg);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      margin-right: 6px;
      animation-name: _loading;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
   }

   @keyframes _loading {
      from {
         transform: rotate(0deg);
      }
      to {
         transform: rotate(360deg);
      }
   }
}

.dataNull {
   height: calc(100vh - 518px);
   padding: 0;
}
