.page_box {
  //margin: 0 auto;
  // width: 452px;
  position: absolute;
  display: flex;
  //justify-content: center;
  align-items: center;
  //margin-left: 150px;
  right: 32px;
  margin-top: -10px;
  //padding-top: -20px;
}
.pageFont{
  margin-left: 8px;
  color: #666666;
  font-size: 14px;
}

ul {
  margin: 0 auto;
}

.pagination-select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  width: 72px;
  option{
    top: 2px;
    text-align: left;
    font-size: 14px;

    &:checked {
      background-color: #e8e9ea;
    }
  }
}

.pagination-select select {
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  border: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.pagenum_pagination_input{
  padding: 0px !important;
  margin-left: 8px;
  border: 1px solid #D9D9D9 ;
  background: #FFFFFF;
  font-size: 14px;
  color: #666666;
  height: 30px;
  width: 70px;
  border-radius: 2px;
  text-align: center;
  outline:none;
}

.pagenum_pagination_input:hover{
  border: 1px solid #19af5d ;
  background: #FFFFFF;
}

.pagenum_pagination_input:focus{
  border: 1px solid #19af5d ;
  background: #FFFFFF;
}

.pagination-btn {
  border: 1px solid #D9D9D9;
  background: #FFFFFF;
  cursor: pointer;
  color: #333333;
  display: inline-block;
  box-sizing: border-box;
  font-size: 14px;
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  margin-left: 8px;
  border-radius: 2px;

  &:hover {
    border: 1px solid #19af5d;
    background-color: #FFFFFF;
  }
}

.pagination-btn-select{
  background: url('../../assets/images/saas/selectIcon.svg') no-repeat;
  background-size: 0.6rem 0.7rem;
  background-position-x: 70px;
  background-position-y: center;
  margin-right: 4px;
  border-radius: 2px;
  border: 1px solid #D9D9D9;
  //background: #FFFFFF;
  cursor: pointer;
  color: #333333;
  display: inline-block;
  box-sizing: border-box;
  width: 90px;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  outline-color:#19af5d;

  &:hover {
    border: 1px solid #19af5d;
    background-color: #FFFFFF;
  }
}

.pagination-wrap {
  position: relative;
  display: inline-block;
  //padding-left: 0px;
  vertical-align: top;
  margin: 0px;
}

.pagination-ellipsis {
  color: #999;
  position: relative;
  top: -5px;
  display: inline-block;
  //padding: 0 0.12rem;
  margin-right: 4px;
}

.pagination-current {
  border: 1px solid #65c886;
  background: white;
  color: #666666;
}

.pagination-disabled {
  height: 32px;
  width: 32px;
  cursor: not-allowed;
  border: 1px solid #D9D9D9;
  background: #F5F5F5;
  color: #adadad;
  border-radius: 2px;
  &:hover {
    border: 1px solid #D9D9D9;
    background: #F5F5F5;
  }
}
.react-select-container{
  //border:1px solid #D9D9D9;
  //color: #666666;
  //height: 32px;
  //border-radius: 2px;
  //&:hover{
  //  border: 1px solid #19af5d;
  //}
  width: 90px;
  font-size: 14px;
}

.react-select-__control {
  border: 1px solid #D9D9D9;
  //border: 1px solid #D9D9D9;
  color: #666666;
  border-radius: 2px !important;
  height: 32px !important;
  min-height: unset !important;
  box-shadow: none;
  outline: none !important;

  &:hover {
    border: 1px solid #19af5d !important;
  }
}

.react-select-__single-value {
  overflow: hidden !important;
  text-overflow: unset !important;
}

.react-select-__control--is-focused {
  border: 1px solid #19af5d !important;
  box-shadow: none !important;
}

.react-select-__value-container {
  height: 30px;
  padding: 0 !important;
  line-height: 30px;
  text-align: right;
}

.react-select-__option {
  font-size: 14px !important;
  color: #666666 !important;
  height: 32px !important;
  line-height: 32px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  overflow: hidden !important;
}

.react-select-__option--is-focused {
  background-color: #f1f2f3 !important;
}

.react-select-__option--is-selected {
  background-color: #e8e9ea !important;
}

.react-select-__menu,
.react-select-__menu-list {
  border: 0 !important;
  outline: 0 !important;
  border-radius: 2px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12) !important;
}
.react-select-__input-container {
  //display: none !important;
}

.react-select-__indicator-separator {
  display: none;
}

.react-select-__indicator {
  padding: unset !important;
}

.pagenum {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
