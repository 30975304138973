.foot {
  background: #f4f4f4;
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0px;
}

.foot .Tem_Container {
  background: #f4f4f4;
}

.foot .beian {
  padding-top: 30px;
  color: #9d9d9d;
  width: 100%;
  font-size: 14px;
  text-align: center;
}
