.report {
  display: inline-block;
  width: calc(20% - 16px);
  min-width: 280px;
  margin: 8px;
  height: 100px;
  position: relative;
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.02);

  .report-vip-tag{
    background-repeat: no-repeat;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 4px;
    left: 4px;
  }

  &:hover #pc.more_class {
    visibility: visible;
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  }
}

.con-img-text {
  height: 100px;
}

.extractTag {
  background-color: rgba(243, 144, 18, 0.10);
  font-size: 12px;
  border-radius: 2px;
  color: #f39012;
  position: absolute;
  right: 45px;
  top: 8px;
  padding: 2px;
}

.shareTag {
  background-color: rgba(243, 144, 18, 0.10);
  font-size: 12px;
  border-radius: 2px;
  color: #f39012;
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 2px;
}

.left-img-div {
  display: inline-block;
  height: 100px;
}

.left-img {
  width: 60px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-left: 20px;
  margin-right: 16px;
  position: relative;
  top: 26px;
}

.more_action_ico {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
  fill: #999999;
}

#pc.more_class {
  visibility: hidden;
}

.more_class {
  position: absolute;
  right: 0;
  bottom: 0px;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  margin-bottom: 12px;
}

#mobile {
  display: none;
}

.more_class:hover .more_action {
  //visibility: visible;
}

.more_class:hover .more_action_ico {
  fill: #19af5d;
}

.more_action a:hover div {
  background-color: #f1f2f3;
}

.share-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 16px;
  margin-right: 8px;
  fill: #333333;
  position: relative;
  top: 3px;
}

.delete-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 16px;
  margin-right: 8px;
  fill: #333333;
  position: relative;
  top: 3px;
}

.more_action {
  visibility: hidden;
  text-align: left;
  background: #fdfeff;
  width: 130px;
  border-radius: 6px;
  position: absolute;
  cursor: pointer;
  line-height: 32px;
  font-size: 16px;
  color: rgb(155, 155, 155);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.30);
  z-index: 99;
  padding: 5px 0 5px 0;

  & > a {
    color: #333333;
  }
}

.report img {
  width: 100%;
  height: 90%;
  position: absolute;
  object-fit: unset;
  border-top: 10px solid white;
  border-bottom: 10px solid white;
}

.report .con {
  width: 100%;
  //height: 100px;
  color: #9b9b9b;
  position: relative;
  align-items: flex-end;
}

.right-context {
  display: inline-block;
  width: calc(100% - 96px);
  vertical-align: top;
  margin-top: 13.5px;
}

.report .con .report_name {
  width: 60%;
  color: #333333;
  font-size: 16px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.report .con .report_path {
  width: 90%;
  color: #333333;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.6;
}


.create_time {
  font-size: 12px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333333;
  opacity: 0.6;
}

.report .con .report_name a {
  color: #515151;
  font-weight: bold;
}

.report .con .topic {
  width: 90%;
  font-size: 14px;
  line-height: 16px;
  height: 33px;
  margin-bottom: 14px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.report .con .topic a {
  color: #9b9b9b;
}

.report .con .other {
  width: 90%;
  font-size: 14px;
  line-height: 2em;
  margin-bottom: 15px;
  margin-top: -10px;
  color: #9b9b9b;
  border-spacing: 0;
}

.report .con .other td {
  padding: 1px 0;
}

.report .con .other span {
  border: 1px #3cbc66 solid;
  border-radius: 5px;
  color: #3cbc66;
  padding: 3px 10px;
}

.PluginItemTips {
  position: absolute;
  top: -60px;
  right: 0px;
  padding: 5px 3px 0 3px;
  text-align: left;
  visibility: hidden;
  width: 133px;
  height: 35px;
  line-height: 15px;
  border: 2px #4a4a4a solid;
  border-radius: 5px;
  font-size: 8px;
  color: white;
  background: #4a4a4a;
  opacity: 0.74;
}

.reportItemBtn {
  cursor: pointer;
}

.report .con .other .reportItemBtn:hover {
  background: #29b758;
  color: white;
}

.PluginItemPurchase:hover .PluginItemTips {
  visibility: visible;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.position-relative {
  position: relative;
}

//能够一排5个，最小宽度
@media screen and (min-width: 1528px) {
  .report {
    width: calc(20% - 16px);
  }
}

// 能够一排4个，最小宽度
@media screen and (min-width: 1232px) and (max-width: 1527px) {
  .report {
    width: calc(25% - 16px);
  }
}

@media screen and (max-device-width: 1000px) {
  .report {
    width: calc(100% - 10px);
    height: calc(188px / 2);
    border-radius: 10px;
    margin: 5px;
  }
  .con-img-text {
    height: calc(188px / 2);
  }
  .left-img {
    width: calc(100px /2) !important;
    height: calc(80px / 2) !important;
    top: calc(54px / 2) !important;
  }
  .left-img-div{
    height: calc(188px / 2);
  }
  .report_name {
    font-size: 15px !important;
  }
  .report_path,
  .create_time {
    font-size: calc(24px / 2) !important;
  }
  .right-context {
    width: calc(100% - 86px);
    //margin-top: calc(33px / 2) !important;
  }
  .shareTag {
    font-size: calc(22px / 2);
  }

  .more_action_ico {
    width: calc(36px / 2);
    height: calc(36px / 2);
    background-size: cover !important;
  }
  .more_class {
    display: block !important;
    margin-right: unset !important;
    margin-bottom: unset !important;
    height: calc(36px / 2);
    width: calc(36px / 2);
    top: calc(76px / 2);
    right: calc(20px / 2);
  }
  .more_class:hover .more_action {
    visibility: hidden;
  }
  #pc {
    display: none;
  }
  #mobile {

  }

  .mobile_click:hover {
    box-shadow: unset;
  }
  .more_action_ico_mobile {
    visibility: visible;

    &:hover {
      fill: none !important;
    }
  }

  .mask {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
  }
}

.mobile_dom {
  width: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0;
  background-color: #F8F9FA;
  border-radius: 20px 20px 0 0;

  hr {
    border: unset;
    border-top: 1px solid #EFEFEF;
    margin: 0;
  }

  div {
    font-size: calc(30px / 2);
    line-height: calc(90px / 2);
    background-color: #FFFFFF;
  }

  .title {
    border-radius: 20px 20px 0 0;
    height: calc(116px / 2);
    padding: 0 30px 0 30px;
    line-height: calc(116px / 2);
    font-size: calc(26px / 2);
    color: #999999;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .share {
    color: #333333;
  }

  .delete {
    color: #E63939;
  }

  .cancel {
    color: #333333;
    margin-top: calc(20px / 2);
  }
}
