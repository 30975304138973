.footer-rolling {
  position: absolute;
  bottom: 14px;
  left: 54px;
  right: 54px;
  height: 38px;
  font-size: 14px;
  color: #a3a3a3;
  border-radius: 0 0 10px 10px;
  background-color: rgba(0, 0, 0,0.65);
  box-shadow: rgba(0, 0, 0, 0.4) 0 2px 6px 0;
}

.rolling-tip {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  :hover{
    cursor: pointer;
  }
  .container {
    overflow: hidden;
    text-align: center;
  }

  .item {
    height: var(--item-height);
    line-height: var(--item-height);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}