button {
  border-radius: 5px;
  border: none;
}

.mask {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
}

.alert {
  width: 400px;
  height: 152px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;

  .alert_head {
    border-radius: 6px 6px 0 0;
    background-color: #F8F9FA;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;

    h3 {
      margin-top: 0;
      font-size: 16px;
      color: #333333;
      width: 90%;
      display: inline-block;
    }
  }

  .alert_icon {
    width: 32px;
    height: 32px;
    display: inline-block;
    background-size: 32px;
    background-repeat: no-repeat;
  }

  .icon_success {
    background-image: url("../../assets/images/saas/icon-success.svg");
  }

  .icon_warning {
    background-image: url("../../assets/images/saas/icon-warning.svg");
  }

  #alert_body {
    margin-left: 12px;
    vertical-align: top;
    width: calc(100% - 44px);
    display: inline-block;
    word-break: break-all;
  }

  .alert_body {
    height: 62px;
    padding: 20px 20px 0 30px;
    font-size: 14px !important;
    overflow-y: scroll;

    &-dom{
      display: flex;
      align-items: center;
      user-select: none;
    }
  }

  .alert_footer {
    height: 52px;
    background-color: white;
    border-radius: 0 0 6px 6px;
    //line-height: 40px;
    padding-right: 30px;

    .alert_btn {
      position: relative;
      display: flex;
      height: 40px;
      align-items: center;
      float: right;

      button {
        margin-left: 20px;
        min-width: 86px;
        height: 32px;
        //font-size: 18px;
        border: none;
        padding: 0 15px 0 15px;
        border-radius: 2px;

        &:hover {
          cursor: pointer;
        }
      }

      .btn_ok {
        background-color: #19af5d;
        color: #FFFFFF;
      }

      .btn_ok:hover {
        background-color: rgba(25, 175, 93, 0.8);
      }

      .btn_cancel {
        background-color: #FFFFFF;
        border: 1px solid #D9D9D9;
        color: #333333;
      }

      .btn_cancel:hover {
        color: #19af5d;
        border-color: #19af5d;
        //background-color: #d3d3d3;
      }
    }
  }
}

.alert_close_button {
  width: 16px;
  height: 16px;
  float: right;
  display: inline-block;
  margin-right: 20px;
  margin-top: 3px;

  path {
    fill: #c2c2c2 !important;
    stroke: #c2c2c2 !important;
  }

  &:hover {
    path {
      fill: #666666 !important;
      stroke: #666666 !important;
    }
  }
}

.alert-mobile{
  width: calc(100% - calc(176px / 2));
  //height: calc(256px / 2);
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  font-size: calc(32px / 2);
  color: #333333;

  &-body{
    //height: calc(166px / 2);
    text-align: center;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &-title{
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%,-50%);
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    min-height: 63px;
    flex: auto;
    align-items: center;
    justify-content:center;
    //max-height: 80%;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
  }
  &-operate{
    //height: calc(88px / 2);
    border-top: 1px solid #EFEFEF;
    background-color: #EFEFEF;
    border-radius: 0 0 10px 10px;

    &-cancel{
      height: calc(88px / 2);
      width: 50%;
      color: #333333;
      font-size: calc(32px / 2);
      border-right: 1px solid #EFEFEF;
      background-color: #ffffff;
      border-radius: 0 0 0 10px !important;
    }

    &-ok{
      height: calc(88px / 2);
      width: 50%;
      color: #19af5d;
      font-size: calc(32px / 2);
      background-color: #ffffff;
      border-radius: 0 0 10px 0 !important;
    }

  }
}