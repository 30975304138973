.Report_Tem_Container {
  margin: 12px 24px 8px 24px;
}

.content.home-content {
  overflow: hidden;
  position: relative;
  margin-top: 64px;
  padding-bottom: 0 !important;
  height: calc(100vh - 144px);
  overflow-y: scroll;

  .bg {
    height: 425px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .banner-anim {
    position: relative;

    &-thumb {
      position: absolute;
      bottom: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      text-align: center;
      pointer-events: none;
      z-index: 10;

      & > span {
        pointer-events: auto;
        cursor: pointer;
        display: inline-block;
        list-style: none;
      }

      &-default {
        height: 50px;
        line-height: 50px;

        & span {
          width: 10px;
          height: 10px;
          border-radius: 10px;
          margin: 0 7px;
          background: #fff;
          opacity: 0.5;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);

          &:active,
          &.active {
            opacity: 1;
            background: #fff;
          }
        }
      }
    }
  }
}

.reports_Item {
  min-width: 1232px !important;
  // margin-bottom: 80px !important;
}

#reportUrl {
  border-right: none;
  flex: auto;
  margin-left: 0;
  margin-right: 0;
}

.bottomBox {
  display: flex;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  //height: calc(100vh - 504px);
}

.button-container {
  max-width: 1229px;
  min-width: 810px;
  padding: 53px 0 80px;
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;

  .button {
    width: 30%;

    &-image {
      width: 100%;
      height: auto;
      display: block;
      transition: all 0.3s;

      &:hover {
        transform: scale(1.1);
        transform-origin: center;
        box-shadow: 0px 4px 8px rgba(81, 81, 81, 0.3);
      }
    }
  }
}

.foot.home-foot {
  height: 80px;
  flex: 0 0 80px;
}

.operate {
  padding: 10px 10px 30px 10px;
  font-size: 14px !important;

  label {
    margin-right: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  div {
    margin: 16px 30px 0 30px;
    display: flex;
    align-items: center;
    line-height: 32px;
  }

  input:not([type='checkbox']) {
    height: 32px;
    color: #515151;
    outline: none;
    border: 1px solid #e5e5e5;
    font-size: 14px;
    padding: 5px;
    vertical-align: text-top;
    border-radius: 2px;
  }

  #sharePwd {
    width: 300px;
  }

  #outDays {
    width: 80px;
  }

  #isOutDays, #isPwd {
    margin-right: 8px;
  }

  input[type=radio] {
    margin-right: 10px;
  }

  hr {
    background-color: #e8ebef;
    border: none;
    height: 1px;
    width: calc(100% - 60px);
  }
}

.link {
  input {
    border-radius: 2px 0 0 2px !important;
  }

  .btn_copy_link {
    border-radius: 0 2px 2px 0;
    margin-left: 0;
  }

  button {
    min-width: 86px;
    height: 32px;
    padding: 0 15px 0 15px;
    border-radius: 2px;
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    color: #333333;

    &:hover {
      color: #19af5d;
      border-color: #19af5d;
      cursor: pointer;
    }
  }
}

.empty {
  text-align: center;
  font-size: 14px;
  color: #666666;
}

.reports_empty {
  background-image: url("../../assets/images/saas/reports-empty.svg");
  width: 170px;
  height: 170px;
  margin: 0 auto 5px auto;
}

.react-switch {
  margin: 0 !important;
  border-radius: 8px;
  line-height: unset !important;
  height: 16px !important;

  div {
    margin: unset !important;
    line-height: unset !important;
  }

  &-handle {
    transition: unset !important;
    box-shadow: unset !important;
  }
}

.more-info {
  margin: 0 !important;
  line-height: unset !important;
  display: block !important;
}

.share-qr-code {
  display: flex;
  align-items: center;
}

@keyframes showQrCode {
  from {
    height: 0;
  }
  to {
    height: 88px;
  }
}

.share-qr-txt {
  margin: 0 0 0 20px !important;
  font-size: 14px;
  color: #333333;
}

.share-club {
  display: block !important;
  margin-top: 16px !important;

  p {
    margin: 0 0 0 28px;
    line-height: 20px;
    font-size: 14px;
    color: #999999;
  }
}

.share-club-link {
  font-size: 14px;
  color: #19af5d;
  margin-left: 12px;
}

@media screen and (max-device-width: 1000px) {

  .react-switch {
    height: 20px !important;
  }
  .content {
    margin-top: 0 !important;
    min-width: unset !important;
  }

  .home-content {
    margin-top: 0 !important;
    height: 100vh !important;
  }

  .reports_Item {
    min-width: unset !important;
  }

  .Report_Tem_Container {
    margin: 10px;
  }
  .report_foot {
    display: none;
  }

  .operate {
    padding: 20px 15px 0 15px !important;
    font-size: 15px !important;

    label {
      margin-right: 8px !important;
      display: flex !important;
      align-items: center !important;
      cursor: pointer !important;
    }

    hr {
      display: none;
    }

    div {
      margin: 0 0 18px 0;
      //height: 32px;
    }

    input[type=checkbox] {
      margin-right: 8px;
      width: 15px;
      height: 15px;

      &:checked {
        font-size: 15px;

        &::after {
          line-height: 15px;
        }
      }
    }

    input:not([type='checkbox']) {
      color: #515151;
      outline: none !important;
      border: 1px solid #e5e5e5;
      font-size: 15px !important;
      padding: 2.5px !important;
      vertical-align: text-top !important;
      border-radius: 2px !important;
      height: 26px !important;
    }

    #sharePwd {
      width: calc(100% - 170px - 32px);
      flex: auto;
    }

    #outDays {
      width: 80px;
    }

    #isOutDays, #isPwd {
      margin-right: 8px;
    }

    #reportUrl {
      height: 26px;
      width: calc(100% - 7px);
      margin-bottom: 15px;
      border: 1px solid #e5e5e5 !important;
    }

    .link {
      display: block;
      margin: 0;
      //height: 79px;

      button {
        height: 32px;
        margin-right: 15px !important;
        font-size: 15px;

        &:hover {
          border: 1px solid #D9D9D9;
          color: #333333;
        }
      }
    }

    hr {
      background-color: #e8ebef;
      border: none;
      height: 1px;
      width: call(calc(100% - 60px) / 2);
    }
  }

  .share-qr-code {
    margin: 18px 0 0 0 !important;
  }

  .share-qr-txt {
    flex-direction: column;
    margin: 0 0 0 15px !important;

    button {
      height: 32px;
      padding: 0 15px 0 15px;
      border-radius: 2px;
      background-color: #FFFFFF;
      border: 1px solid #D9D9D9;
      color: #333333;
      margin-top: 15px;
    }
  }

  .share-club p {
    font-size: 12px;
    line-height: 16px;
    margin-top: 12px;
  }
}
